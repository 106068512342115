import React, { useCallback, useEffect, useState } from 'react';
// React Router
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
// Translation
import { useTranslation } from 'react-i18next';
// Redux
import { useSelector } from 'react-redux';
// Hooks
import { useAppDispatch } from 'hooks/useAppDispatch';
import {
  fetchCloneContact,
  selectContactData,
  selectContactFetchStatus,
} from 'ReduxToolkit/features/contactsSlice/contactsSlice';
// Components/ui
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Skeleton,
  styled,
  Tooltip,
  tooltipClasses,
  Typography,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
// Components
import { AvatarViewer, DeleteContactModal, Labels } from './components';
import useImage from 'hooks/useImage';
import { setAvoidReset } from 'ReduxToolkit/features/contactsFilterSlice/contactsFilterSlice';

const FORMAT_DATE = 'DD/MM/YYYY HH:mm:ss';

type TooltipProps = {
  className?: string;
  title: string;
  placement: 'bottom';
};

const HeaderContactViewer = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const contactData = useSelector(selectContactData);
  const contactViewerStatus = useSelector(selectContactFetchStatus);

  const lastUpdateDate = moment(contactData.updatedAt).format(FORMAT_DATE);

  const { contactId } = useParams();
  const contactImage = useImage(contactData.imageUrl);

  const [userName, setUserName] = useState('User Name');
  const [openModal, setOpenModal] = useState(false);
  const [openAvatarModal, setOpenAvatarModal] = useState(false);

  const openEditContact = useCallback(() => {
    navigate(`/a/contacts/contacts/${contactData.id}/edit`);
  }, [contactData]);

  const backToList = useCallback(() => {
    dispatch(setAvoidReset(true));
    navigate('/a/contacts/contacts');
  }, []);

  const TooltipLastUpdate = styled(
    ({ className, title, placement }: TooltipProps) => (
      <Tooltip classes={{ popper: className }} title={title} placement={placement}>
        <Typography variant="caption">{title}</Typography>
      </Tooltip>
    )
  )(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      // backgroundColor.palette.common.white,
      top: -7,
    },
  }));

  // ACCIONES
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClone = () => {
    dispatch(fetchCloneContact(contactId));
    handleCloseMenu();
  };

  const handleOpen =
    (setState: React.Dispatch<React.SetStateAction<boolean>>) => () => {
      setState(true);
      handleCloseMenu();
    };

  const handleCloseAvatarViewer = () => {
    setOpenAvatarModal(false);
  };

  const handleNavigate = () => {
    navigate(`/a/contacts/duplicates?contactId=${contactId}`);
  };

  const stringAvatar = (name: string) => {
    if (name) {
      const firstLetter = `${name.split(' ')[0][0]}`;

      return {
        children: `${firstLetter}`,
      };
    }

    return {
      children: '',
    };
  };

  console.log('Contact data: ', contactData);

  useEffect(() => {
    setUserName(contactData.name);
  }, [contactData.name]);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        borderTopLeftRadius: '0px',
        borderBottomLeftRadius: '0px',
        borderBottomRightRadius: '0px',
        borderBottom: '1px solid',
        borderColor: (theme) => theme.palette.divider,
        //@ts-ignore
        backgroundColor: (theme) => theme.palette.background.dropdownBox,
        color: (theme) => theme.palette.text.primary,
      }}
      height="auto"
      px={2}
      pl={1}
      py={1}
    >
      <Box width="100%">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center">
            <IconButton
              size="small"
              onClick={backToList}
              sx={{ color: (theme) => theme.palette.text.primary }}
            >
              <ArrowBackIcon />
            </IconButton>
            <IconButton size="small" onClick={handleOpen(setOpenAvatarModal)}>
              {contactViewerStatus === 'loading' ? (
                <Skeleton
                  height={38}
                  width={38}
                  variant="circular"
                  sx={{ backgroundColor: '#f5f5f5' }}
                  animation="wave"
                />
              ) : (
                <Avatar
                  src={contactData.imageUrl !== '' ? contactImage.imageUrl : ''}
                  alt={contactData.name}
                  sx={{
                    cursor: 'pointer',
                    border: '1px solid #ced1d5',
                    bgcolor: (theme) => `${theme.palette.primary.main}50`,
                    color: (theme) => theme.palette.text.secondary,
                  }}
                />
              )}
            </IconButton>
            <Box maxWidth="40vw" ml={2}>
              {contactViewerStatus === 'succeeded' ? (
                <>
                <Tooltip title={contactData.name}>

                  <Typography
                    variant="subtitle1"
                    mb={'-5px'}
                    className="ellipsified"
                    sx={{
                      wordWrap: 'break-word',
                      textAlign: 'center',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {contactData.name}
                  </Typography>
                </Tooltip>
                  <Box display="flex" sx={{ alignItems: 'baseline' }}>
                    <Labels labels={contactData.tags} />
                  </Box>
                </>
              ) : contactViewerStatus === 'loading' ? (
                <Skeleton
                  height={60}
                  width={100}
                  sx={{ backgroundColor: '#f5f5f5' }}
                  animation="wave"
                />
              ) : null}
            </Box>
          </Box>
          <Box display="flex" flexDirection={'column'} alignItems="flex-end">
            <Box>
              <Button
                sx={{ alignSelf: 'center', bgcolor: 'primary.main' }}
                variant="contained"
                size="small"
                onClick={openEditContact}
              >
                {t('global.edit')}
              </Button>
              <Button
                sx={{ alignSelf: 'center', ml: 1 }}
                endIcon={open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                variant="outlined"
                size="small"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClickMenu}
              >
                {t('global.actions')}
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleCloseMenu}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem onClick={handleClone}>
                  {t('contactsView.actions.clone')}
                </MenuItem>
                <MenuItem onClick={handleOpen(setOpenModal)}>
                  {t('contactsView.actions.delete')}
                </MenuItem>
                <MenuItem disabled>
                  {t('contactsView.actions.printPreview')}
                </MenuItem>
                <MenuItem onClick={handleNavigate}>
                  {t('contactsView.actions.findMergeDuplicates')}
                </MenuItem>
              </Menu>
            </Box>
            <Box display={'flex'} mt={1} sx={{ opacity: 0.7 }}>
              <Typography variant="caption" mr={1}>
                {t('contactDetails.lastUpdate')}:
              </Typography>
              <TooltipLastUpdate title={lastUpdateDate} placement="bottom" />
            </Box>
          </Box>
        </Box>
      </Box>
      <DeleteContactModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        contactName={contactData.name}
      />
      <AvatarViewer
        open={openAvatarModal}
        avatar={contactData.imageUrl !== '' ? contactImage.imageUrl : ''}
        name={stringAvatar(userName).children}
        handleClose={handleCloseAvatarViewer}
      />
    </Box>
  );
};

export default HeaderContactViewer;
